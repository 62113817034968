<template>
    <ModalBox
        :is-active.sync="isModalActive"
        @submit="addIntegration"
        @after-leave="close">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.integrations.modal.header.${ isEdit ? 'edit' : 'new' }Integration`) }}
            </h5>
        </template>
        <div>
            <div class="column is-narrow">
                <b-field>
                    <b-switch
                        v-model="isLaunched"
                        :disabled="isEdit">
                        {{ $t("admin.integrations.modal.body.status") }}
                    </b-switch>
                </b-field>
            </div>
            <div class="columns">
                <div class="column is-6">
                    <b-field :label="$t('admin.integrations.modal.body.offer')">
                        <OffersSelect
                            v-model="offer"
                            :disabled="isEdit"
                            namespace-module="admin/integration/edit/offersFilters"
                            :placeholder="$t(`admin.integrations.modal.body.offer`)">
                        </OffersSelect>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field :label="$t(`admin.integrations.modal.body.advertiser`)">
                        <Select
                            v-model="advertiser"
                            :disabled="!offer"
                            :get-data-vuex="getAdvertisers"
                            :min-required-length="1"
                            :placeholder="$t(`admin.integrations.modal.body.advertiser`)"
                            :searchable="false"
                            :seed="offer"
                            field="login">
                            <template #text="option">
                                <UserOutput :user="option.option"></UserOutput>
                            </template>
                            <template #selected-option="option">
                                <UserOutput :user="option.option"></UserOutput>
                            </template>
                        </Select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field :label="$t(`admin.integrations.modal.body.CRM`)">
                        <Select
                            v-model="crm"
                            :disabled="isEdit"
                            :get-data-vuex="getCrmList"
                            :placeholder="$t(`admin.integrations.modal.body.CRM`)"
                            field="name"
                            prop="alias">
                        </Select>
                    </b-field>
                </div>
            </div>

            <LLoading
                :active="loading.loadCrm"
                :is-full-page="false"></LLoading>

            <div
                :key="option.name"
                v-for="option in options">
                <b-field
                    :label="option.name"
                    class="py-1">
                    <LInput
                        v-model="option.value"
                        :required="option.isRequired">
                    </LInput>
                </b-field>
            </div>

            <div
                v-if="!isCrmSelected"
                class="hero has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                        {{ $t("admin.integrations.modal.body.selectCrm") }}
                    </h2>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            :loading="loading.creationIntegration || loading.editIntegration"
                            :disabled="!isAbleToCreate"
                            class="is-fullwidth"
                            native-type="submit"
                            type="is-success is-light">
                            {{ $t(`admin.integrations.modal.footer.save`) }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import {
    GET_OFFER_ADVERTISERS_LIST,
    CREATE_INTEGRATION,
    GET_CRM_LIST,
    GET_CRM_OPTIONS,
    GET_INITIAL_CRM,
    EDIT_INTEGRATION,
    GET_INTEGRATIONS,
    SET_EMPTY
  } from "@core/store/action-constants";
  import {
    SET_ADVERTISER_ID,
    SET_CRM_ALIAS,
    SET_INTEGRATION_OPTIONS,
    UPDATE_MODAL_PARAMS,
    SET_IS_LAUNCHED,
    SET_OFFER_ID,
    UPDATE_CRM_FILTERS,
    UPDATE_CRM_LIST_PAGINATION,
    UPDATE_INTEGRATION_FILTERS,
    UPDATE_INTEGRATION_OPTIONS,
    UPDATE_OFFER_ADVERTISERS_FILTERS,
    UPDATE_OFFER_ADVERTISERS_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import LLoading from "@/components/Common/LLoading";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";

  export default {
    name: "IntegrationsNewIntegrationModal",
    components: {
      LLoading,
      ModalBox,
      Select,
      UserOutput,
      OffersSelect
    },

    computed: {
      ...mapState("admin/integration/edit", {
        crmList: state => state.CRMList.data,
        integrationId: state => state.params.integrationId,
        _isModalActive: ({ isModalActive }) => isModalActive,
        offerAdvertisersList: state => state.offerAdvertisersList.data
      }),

      ...mapFields("admin/integration/edit", {
        fields: ["advertiser", "offer", "crm"],
        base: "filters",
        action: UPDATE_INTEGRATION_FILTERS
      }),

      ...mapFields("admin/integration/edit", {
        fields: ["options"],
        base: "",
        action: UPDATE_INTEGRATION_OPTIONS
      }),

      isAbleToCreate () {
        return Boolean(this.offer && this.advertiser && this.crm);
      },

      isEdit () {
        return Boolean(this.integrationId);
      },

      loading () {
        return {
          creationIntegration: this.$wait(`admin/integration/edit/${ CREATE_INTEGRATION }`),
          editIntegration: this.$wait(`admin/integration/edit/${ EDIT_INTEGRATION }`),
          loadCrm: this.$wait(`admin/integration/edit/${ GET_CRM_OPTIONS }`)
        };
      },

      isCrmSelected () {
        return this.$store.getters["admin/integration/edit/isCrmSelected"];
      },

      isModalActive: {
        get () {
          return this._isModalActive;
        },

        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
        }
      },

      isLaunched: {
        get () {
          return this.$store.state.admin.integration.edit.params.isLaunched;
        },

        set (value) {
          this.UPDATE_MODAL_PARAMS({ isLaunched: value });
        }
      },

      integrationOldValue: {
        get () {
          return this.crm;
        },

        set (value) {
          if (value) this.crm = value;
        }
      }
    },
    methods: {
      ...mapMutations("admin/integration/edit", {
        OFFER_ADVERTISERS_SET_EMPTY: `offerAdvertisersList/${ SET_EMPTY }`,
        UPDATE_OFFER_ADVERTISERS_PAGINATION: `offerAdvertisersList/${ UPDATE_OFFER_ADVERTISERS_PAGINATION }`,
        UPDATE_OFFER_ADVERTISERS_FILTERS: `offerAdvertisersList/${ UPDATE_OFFER_ADVERTISERS_FILTERS }`,
        UPDATE_CRM_LIST_PAGINATION: `CRMList/${ UPDATE_CRM_LIST_PAGINATION }`,
        UPDATE_CRM_FILTERS: `CRMList/${ UPDATE_CRM_FILTERS }`,
        UPDATE_MODAL_PARAMS,
        SET_OFFER_ID,
        SET_ADVERTISER_ID,
        SET_INTEGRATION_OPTIONS,
        SET_IS_LAUNCHED,
        SET_CRM_ALIAS
      }),

      ...mapActions("admin/integration/edit", {
        GET_CRM_LIST: `CRMList/${ GET_CRM_LIST }`,
        GET_INITIAL_CRM: `CRMList/${ GET_INITIAL_CRM }`,
        GET_OFFER_ADVERTISERS_LIST: `offerAdvertisersList/${ GET_OFFER_ADVERTISERS_LIST }`,
        CREATE_INTEGRATION,
        EDIT_INTEGRATION,
        GET_CRM_OPTIONS,
        SET_EMPTY,
        UPDATE_MODAL_ACTIVE
      }),

      ...mapActions("admin/integrations", [
        GET_INTEGRATIONS
      ]),

      close () {
        this.SET_EMPTY();
      },

      async getAdvertisers () {
        if (this.offer) {
          this.UPDATE_OFFER_ADVERTISERS_PAGINATION();
          await this.GET_OFFER_ADVERTISERS_LIST(this.offer);
          return this.offerAdvertisersList;
        }
        return { items: [], count: 0 };
      },

      async addIntegration () {
        const dataSet = {
          "false": { action: this.CREATE_INTEGRATION, text: "create" },
          "true": { action: this.EDIT_INTEGRATION, text: "edit" }
        };
        await actionWithToast(
          dataSet[this.isEdit].action(),
          `admin.integrations.messages.${ dataSet[this.isEdit].text }`
        );
        this.isModalActive = false;
        this.close();
      },

      async getCrmList (label = "") {
        this.UPDATE_CRM_LIST_PAGINATION();
        this.UPDATE_CRM_FILTERS({ name: label.length > 0 ? label : null });
        if (this.crmList?.items?.length > 0) {
          await this.GET_CRM_LIST();
        }
        else {
          const res = await this.GET_INITIAL_CRM(this.crm);
          if (res?.length > 0) {
            this.crm = res[0];
          }
        }
        return this.crmList;
      }
    },

    watch: {
      offer () {
        if (!this.isEdit) {
          this.advertiser = null;
          this.OFFER_ADVERTISERS_SET_EMPTY();
        }
      },

      crm (value) {
        if (!this.isEdit) {
          if (value) {
            this.GET_CRM_OPTIONS(value);
          } else {
            this.SET_INTEGRATION_OPTIONS([]);
          }
        }
      }
    },

    destroyed () {
      this.close();
    }
  };
</script>

<style lang="scss" scoped>
</style>
