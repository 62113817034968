<template>
    <SectionWrapper
        :action-button-options="actionButtonOptions"
        @action="UPDATE_MODAL_ACTIVE(true)">
        <template #sticky>
            <IntegrationsFilters></IntegrationsFilters>
        </template>
        <IntegrationsTable></IntegrationsTable>
    </SectionWrapper>
</template>

<script>
  import { mapActions } from "vuex";
  import { SET_EMPTY } from "@core/store/action-constants";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import IntegrationsTable from "@/components/Admin/Integrations/IntegrationsTable";
  import IntegrationsFilters from "@/components/Admin/Integrations/IntegrationsFilters";
  import SectionWrapper from "@/components/Common/SectionWrapper";

  export default {
    name: "Integrations",
    components: {
      IntegrationsFilters,
      IntegrationsTable,
      SectionWrapper
    },

    computed: {
      actionButtonOptions () {
        return {
          label: this.$t("admin.integrations.tooltip.integrationsNewOffer"),
          permissions: ["INTEGRATIONS.CREATE"]
        };
      }
    },

    methods: {
      ...mapActions("admin/integrations", [
        SET_EMPTY
      ]),

      ...mapActions("admin/integration/edit", {
        UPDATE_MODAL_ACTIVE
      })
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    .integration {
        &-select {
            margin-bottom: 10px;
        }
    }
</style>
