<template>
    <TablePlaceholder :data="integrations">
        <template #default="{ items, count }">
            <LTable
                :data="items"
                :loading="isLoading.getIntegration"
                :count="count"
                virtual-scroll
                backend-pagination
                query-pagination
                with-options
                @update="GET_INTEGRATIONS">
                <b-table-column
                    v-slot="{ row }"
                    centered>
                    <ColoredStatus :type="coloredStatusIntegration(row.isLaunched)"></ColoredStatus>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.integrations.table.labels.offer`)">
                    <CustomOfferOutput :offer="row.offer"></CustomOfferOutput>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.integrations.table.labels.advertiser`)">
                    <AdvertiserOutput :advertiser="row.advertiser"></AdvertiserOutput>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.integrations.table.labels.errors`)">
                    <ColoredStatus :type="coloredStatusErrors(row.lastCheckedAt, row.errors.length)">
                        <template v-if="row.lastCheckedAt">
                            <template v-if="row.errors.length === 0">
                                {{ $t(`admin.integrations.table.values.errors.empty`) }}
                            </template>
                            <LTooltip
                                v-else
                                class="line_break_hack"
                                position="is-bottom"
                                type="is-dark">
                                <template #content>
                                    <ul class="is-marginless">
                                        <li
                                            :key="item"
                                            v-for="item in row.errors">
                                            {{ item }}
                                        </li>
                                    </ul>
                                </template>
                                {{ $t(`admin.integrations.table.values.errors.numberOfErrors`, { errorsCount: row.errors.length })
                                }}
                            </LTooltip>
                        </template>
                        <template v-else>
                            {{ $t(`admin.integrations.table.values.errors.noData`) }}
                        </template>
                    </ColoredStatus>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.integrations.table.labels.lastCheckedAt`)">
                    <template v-if="row.crm.alias === 'lg'">
                        <div class="is-flex is-align-items-center">
                            <a @click="validateCrm(row)">
                                {{
                                    row.lastCheckedAt
                                        ? momentFrom(row.lastCheckedAt)
                                        : $t(`admin.integrations.table.values.lastCheckedAt.neverUpdated`)
                                }}
                            </a>
                            <InlineLoader
                                :active="loadingRowId === row.id && isLoading.validateCrm">
                            </InlineLoader>
                        </div>
                    </template>
                    <template v-else>
                        {{
                            $t(`admin.integrations.table.values.lastCheckedAt.unsupportedCrm`,
                               { crmName: row.crm.name })
                        }}
                    </template>
                </b-table-column>

                <b-table-column v-slot="{ row }">
                    <Permissions
                        v-if="!row.isLaunched"
                        :permissions="['INTEGRATIONS.LAUNCH']">
                        <LTooltip
                            :label="$t(`admin.integrations.table.dropdown.launch`)"
                            position="is-left"
                            type="is-success">
                            <b-button
                                icon-left="play"
                                rounded
                                :loading="isLoadingButtons(row.id)"
                                size="is-small"
                                type="is-success is-light"
                                @click="launchIntegration(row.id)">
                            </b-button>
                        </LTooltip>
                    </Permissions>

                    <Permissions
                        v-else
                        :permissions="['INTEGRATIONS.STOP']">
                        <LTooltip
                            :label="$t(`admin.integrations.table.dropdown.stop`)"
                            position="is-left"
                            type="is-danger">
                            <b-button
                                icon-left="stop"
                                rounded
                                :loading="isLoadingButtons(row.id)"
                                size="is-small"
                                type="is-danger is-light"
                                @click="stopIntegration(row.id)">
                            </b-button>
                        </LTooltip>
                    </Permissions>

                    <Permissions :permissions="['INTEGRATIONS.EDIT']">
                        <LTooltip
                            :label="$t(`admin.integrations.table.dropdown.edit.${ !row.crm.options.length ? 'disabled' : 'enabled' }`)"
                            position="is-left"
                            type="is-warning">
                            <b-button
                                icon-left="pen"
                                rounded
                                :disabled="!row.crm.options.length"
                                size="is-small"
                                type="is-warning is-light"
                                @click="editIntegration(row)">
                            </b-button>
                        </LTooltip>
                    </Permissions>

                    <Permissions :permissions="['INTEGRATIONS.REMOVE']">
                        <LTooltip
                            :label="$t(`admin.integrations.table.dropdown.delete`)"
                            position="is-left"
                            type="is-danger">
                            <b-button
                                icon-left="trash-alt"
                                rounded
                                :loading="isLoadingButtons(row.id)"
                                size="is-small"
                                type="is-danger is-light"
                                @click="handleDelete(row)">
                            </b-button>
                        </LTooltip>
                    </Permissions>
                </b-table-column>
            </LTable>
        </template>
    </TablePlaceholder>
</template>

<script>
  import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
  import AdvertiserOutput from "@/components/Common/Output/AdvertiserOutput.vue";
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import Permissions from "@/components/Common/Permissions.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { momentFrom } from "@core/filters";
  import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
  import {
    SET_INTEGRATION_FILTERS,
    DELETE_INTEGRATION,
    GET_INTEGRATIONS,
    GET_CRM_OPTIONS,
    LAUNCH_INTEGRATION,
    STOP_INTEGRATION,
    VALIDATE_CRM_LEAD_GROUP
  } from "@core/store/action-constants";
  import {
    SET_INTEGRATION_OPTIONS,
    UPDATE_MODAL_ACTIVE,
    UPDATE_MODAL_PARAMS
  } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import LTable from "@/components/Common/LTable";

  export default {
    name: "IntegrationsTable",
    components: {
      Permissions,
      ColoredStatus,
      InlineLoader,
      TablePlaceholder,
      CustomOfferOutput,
      AdvertiserOutput,
      LTable
    },
  
    data () {
      return {
        loadingRowId: null
      };
    },

    computed: {
      ...mapState("admin/integrations", {
        integrations: state => state.integrations
      }),

      isLoading () {
        return {
          getIntegration: this.$wait(`admin/integrations/${ GET_INTEGRATIONS }`),
          actionIntegration: this.$wait(`admin/integrations/${ LAUNCH_INTEGRATION }`) ||
            this.$wait(`admin/integrations/${ STOP_INTEGRATION }`) ||
            this.$wait(`admin/integrations/${ DELETE_INTEGRATION }`),
          validateCrm: this.$wait(`admin/integrations/${ VALIDATE_CRM_LEAD_GROUP }`)
        };
      }
    },
    methods: {
      ...mapActions("admin/integrations", [
        GET_INTEGRATIONS,
        DELETE_INTEGRATION,
        VALIDATE_CRM_LEAD_GROUP,
        LAUNCH_INTEGRATION,
        STOP_INTEGRATION
      ]),

      ...mapActions("admin/integration/edit", [
        GET_CRM_OPTIONS
      ]),

      ...mapMutations("admin/integration/edit", [
        SET_INTEGRATION_FILTERS,
        SET_INTEGRATION_OPTIONS,
        UPDATE_MODAL_ACTIVE,
        UPDATE_MODAL_PARAMS
      ]),
  
      isLoadingButtons (id) {
        return this.loadingRowId === id && this.isLoading.actionIntegration;
      },

      momentFrom (date) {
        return momentFrom(date);
      },

      coloredStatusIntegration (status) {
        return status ? "is-success" : "is-danger";
      },

      coloredStatusErrors (lastCheckedAt, errors) {
        if (lastCheckedAt) {
          return errors > 0 ? "is-danger" : "is-success";
        }
        return "is-warning";
      },

      async validateCrm ({ id }) {
        try {
          this.loadingRowId = id;
          await this.VALIDATE_CRM_LEAD_GROUP(id);
        } finally {
          this.loadingRowId = null;
        }
      },

      handleDelete ({ id }) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.integrations.modal.header.deleteIntegrationTitle"),
          message: this.$t("admin.integrations.modal.body.deleteMessage"),
          confirmText: this.$t("admin.integrations.modal.footer.delete"),
          cancelText: this.$t("admin.integrations.modal.footer.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            try {
              this.loadingRowId = id;
              await actionWithToast(this.DELETE_INTEGRATION(id), "admin.integrations.messages.delete");
            } finally {
              this.loadingRowId = null;
            }
          }
        });
      },

      async launchIntegration (id) {
        try {
          this.loadingRowId = id;
          await actionWithToast(this.LAUNCH_INTEGRATION(id), "admin.integrations.messages.launch");
        } finally {
          this.loadingRowId = null;
        }
      },

      stopIntegration (id) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.integrations.modal.header.stopIntegrationTitle"),
          message: this.$t("admin.integrations.modal.body.stopMessage"),
          confirmText: this.$t("admin.integrations.modal.footer.stopText"),
          cancelText: this.$t("admin.integrations.modal.footer.cancel"),
          type: "is-danger",
          onConfirm: async () => {
            try {
              this.loadingRowId = id;
              await actionWithToast(this.STOP_INTEGRATION(id), "admin.integrations.messages.stop");
            } finally {
              this.loadingRowId = null;
            }
          }
        });
      },

      editIntegration (integration) {
        const fromObjectToArrayObjects =
          Object.entries(integration.options).map((e) => ({ name: e[0], value: e[1] }));
        const { isLaunched, id: integrationId, crm: { options: crmOptions } } = integration;
        const mergerOptions = crmOptions.map((item, idx) => ({ ...item, ...fromObjectToArrayObjects[idx] }));

        this.UPDATE_MODAL_ACTIVE(true);
        this.UPDATE_MODAL_PARAMS({ integrationId, isLaunched });
        this.SET_INTEGRATION_FILTERS(integration);
        this.SET_INTEGRATION_OPTIONS(mergerOptions);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .line_break_hack.b-tooltip:after {
        white-space: pre;
    }

    ::v-deep {
      ul {
        list-style: none;
      }
    }
</style>
