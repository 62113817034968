var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.integrations},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"loading":_vm.isLoading.getIntegration,"count":count,"virtual-scroll":"","backend-pagination":"","query-pagination":"","with-options":""},on:{"update":_vm.GET_INTEGRATIONS}},[_c('b-table-column',{attrs:{"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ColoredStatus',{attrs:{"type":_vm.coloredStatusIntegration(row.isLaunched)}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.integrations.table.labels.offer")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CustomOfferOutput',{attrs:{"offer":row.offer}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.integrations.table.labels.advertiser")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('AdvertiserOutput',{attrs:{"advertiser":row.advertiser}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.integrations.table.labels.errors")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ColoredStatus',{attrs:{"type":_vm.coloredStatusErrors(row.lastCheckedAt, row.errors.length)}},[(row.lastCheckedAt)?[(row.errors.length === 0)?[_vm._v(" "+_vm._s(_vm.$t("admin.integrations.table.values.errors.empty"))+" ")]:_c('LTooltip',{staticClass:"line_break_hack",attrs:{"position":"is-bottom","type":"is-dark"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',{staticClass:"is-marginless"},_vm._l((row.errors),function(item){return _c('li',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),0)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("admin.integrations.table.values.errors.numberOfErrors", { errorsCount: row.errors.length }))+" ")])]:[_vm._v(" "+_vm._s(_vm.$t("admin.integrations.table.values.errors.noData"))+" ")]],2)]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.integrations.table.labels.lastCheckedAt")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.crm.alias === 'lg')?[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('a',{on:{"click":function($event){return _vm.validateCrm(row)}}},[_vm._v(" "+_vm._s(row.lastCheckedAt ? _vm.momentFrom(row.lastCheckedAt) : _vm.$t("admin.integrations.table.values.lastCheckedAt.neverUpdated"))+" ")]),_c('InlineLoader',{attrs:{"active":_vm.loadingRowId === row.id && _vm.isLoading.validateCrm}})],1)]:[_vm._v(" "+_vm._s(_vm.$t("admin.integrations.table.values.lastCheckedAt.unsupportedCrm", { crmName: row.crm.name }))+" ")]]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isLaunched)?_c('Permissions',{attrs:{"permissions":['INTEGRATIONS.LAUNCH']}},[_c('LTooltip',{attrs:{"label":_vm.$t("admin.integrations.table.dropdown.launch"),"position":"is-left","type":"is-success"}},[_c('b-button',{attrs:{"icon-left":"play","rounded":"","loading":_vm.isLoadingButtons(row.id),"size":"is-small","type":"is-success is-light"},on:{"click":function($event){return _vm.launchIntegration(row.id)}}})],1)],1):_c('Permissions',{attrs:{"permissions":['INTEGRATIONS.STOP']}},[_c('LTooltip',{attrs:{"label":_vm.$t("admin.integrations.table.dropdown.stop"),"position":"is-left","type":"is-danger"}},[_c('b-button',{attrs:{"icon-left":"stop","rounded":"","loading":_vm.isLoadingButtons(row.id),"size":"is-small","type":"is-danger is-light"},on:{"click":function($event){return _vm.stopIntegration(row.id)}}})],1)],1),_c('Permissions',{attrs:{"permissions":['INTEGRATIONS.EDIT']}},[_c('LTooltip',{attrs:{"label":_vm.$t(("admin.integrations.table.dropdown.edit." + (!row.crm.options.length ? 'disabled' : 'enabled'))),"position":"is-left","type":"is-warning"}},[_c('b-button',{attrs:{"icon-left":"pen","rounded":"","disabled":!row.crm.options.length,"size":"is-small","type":"is-warning is-light"},on:{"click":function($event){return _vm.editIntegration(row)}}})],1)],1),_c('Permissions',{attrs:{"permissions":['INTEGRATIONS.REMOVE']}},[_c('LTooltip',{attrs:{"label":_vm.$t("admin.integrations.table.dropdown.delete"),"position":"is-left","type":"is-danger"}},[_c('b-button',{attrs:{"icon-left":"trash-alt","rounded":"","loading":_vm.isLoadingButtons(row.id),"size":"is-small","type":"is-danger is-light"},on:{"click":function($event){return _vm.handleDelete(row)}}})],1)],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }