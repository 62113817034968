<template>
    <Integrations></Integrations>
</template>

<script>
  import Integrations from "@/components/Admin/Integrations/Integrations";

  export default {
    name: "IntegrationsView",
    components: { Integrations }
  };
</script>

<style scoped>

</style>