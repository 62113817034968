<template>
    <LFilters
        :filters="filters"
        :loading="isLoading"
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-multiline is-real-mobile-flex is-12 mb-2">
                <AccessControlFilter
                    v-model="advertiser"
                    type="advertiser"
                    route-param="advertiser"
                    :placeholder="$t(`common.entity.filters.advertiser`)">
                </AccessControlFilter>

                <AccessControlFilter
                    v-model="offer"
                    type="offer"
                    route-param="offer"
                    :placeholder="$t(`common.entity.filters.offer`)">
                </AccessControlFilter>

                <FilterWrapper>
                    <b-field :label="$t(`common.entity.filters.CRM`)">
                        <CRMSelect
                            v-model="crm"
                            :placeholder="$t(`common.entity.filters.CRM`)"></CRMSelect>
                    </b-field>
                </FilterWrapper>

                <FilterWrapper>
                    <b-field :label="$t(`common.entity.filters.country`)">
                        <CountriesSelect
                            v-model="country"
                            multiple
                            :placeholder="$t(`common.entity.filters.country`)">
                        </CountriesSelect>
                    </b-field>
                </FilterWrapper>

                <IntegrationsNewIntegrationModal>
                </IntegrationsNewIntegrationModal>
            </div>
        </template>
    </LFilters>
</template>

<script>
  import IntegrationsNewIntegrationModal from "@/components/Admin/Integrations/IntegrationsNewIntegrationModal";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import CRMSelect from "@/components/Admin/Select/CRMSelect.vue";
  import LFilters from "@/components/Common/LFilters";
  import { GET_INTEGRATIONS } from "@core/store/action-constants";
  import {
    UPDATE_INTEGRATIONS_FILTERS,
    UPDATE_FILTERS,
    SET_EMPTY
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";

  export default {
    name: "IntegrationsFilters",

    components: {
      AccessControlFilter,
      IntegrationsNewIntegrationModal,
      CountriesSelect,
      CRMSelect,
      LFilters
    },

    computed: {
      ...mapFields("filtersConfirm", {
        fields: ["advertiser", "offer", "crm", "country"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("admin/integrations", {
        crmList: ({ CRMList }) => CRMList.data,
        filters: ({ filters }) => filters
      }),

      isLoading () {
        return this.$wait(`admin/integrations/${ GET_INTEGRATIONS }`);
      }
    },

    methods: {
      ...mapActions("admin/integrations", {
        SET_EMPTY,
        GET_INTEGRATIONS,
        UPDATE_INTEGRATIONS_FILTERS
      }),

      confirmFilters ({ filters }) {
        this.UPDATE_INTEGRATIONS_FILTERS(filters);
        this.GET_INTEGRATIONS();
      }
    }
  };
</script>

<style scoped>
</style>
